import styled from "styled-components";

export const HeroSection = styled.section`
  width: 100%;
  height: auto;

  h1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
`;
