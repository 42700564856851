import styled from "styled-components";

export const MainLayout = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  width: 100vw;
  height: auto;
  min-height: calc(100dvh - 100px - 50px);
  margin-top: 100px;
`;

export const BGImageWrapper = styled.div`
  display: flex;
  width: 60vw;
  height: 60vh;

  position: absolute;
  bottom: 0;
  left: 35px;
  z-index: 0;
  user-select: none;
`;
