import Image from "next/image";
import { ReactNode } from "react";
import bgLogo from "./bg-logo.svg";

import * as S from "./styles";

interface MainLayoutProps {
  children: ReactNode;
}

const MainLayout = ({ children }: MainLayoutProps) => {
  return (
    <S.MainLayout>
      <S.BGImageWrapper>
        <Image
          alt="Logo Backgorund"
          src={bgLogo}
          quality={100}
          fill
          sizes="100vw"
          style={{
            objectFit: "contain",
            pointerEvents: "none",
          }}
          unselectable="on"
        />
      </S.BGImageWrapper>
      {children}
    </S.MainLayout>
  );
};

export default MainLayout;
