import { Button, Form, Input, Typography } from "antd";
import { useEffect, useState } from "react";
import { iUserResponse, userMessages } from "./types";

import * as S from "./styles";

const CallToAction = () => {
  const [form] = Form.useForm();
  const [clientReady, setClientReady] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<iUserResponse | null>(null);

  useEffect(() => {
    setClientReady(true);
  }, []);

  const onFinish = async (values: { email: string }) => {
    setLoading(true);
    try {
      const res = await fetch("/api/subscribe", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: values.email }),
      });

      const isJson = res.headers.get("content-type")?.includes("application/json");
      const sysMessage = isJson ? await res.json() : { message: await res.text() };
      const userMessage = res.ok ? userMessages.success : userMessages.error;

      setResponse({ code: res.status, sysMessage, userMessage });
    } catch (error) {
      setResponse({ code: 500, sysMessage: JSON.parse("Error inserting data"), userMessage: userMessages.error });
      console.error("Error:", userMessages.error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <S.CallToAction>
      <Form form={form} name="register" layout="inline" onFinish={onFinish}>
        <Form.Item name="email" rules={[{ required: true, message: "E-mail inválido" }]} style={{ marginInlineEnd: "0", borderTopRightRadius: "0" }}>
          <Input type="email" placeholder="E-mail" size="large" style={{ width: "300px", borderTopRightRadius: "0", borderBottomRightRadius: "0" }} />
        </Form.Item>
        <Form.Item shouldUpdate style={{ marginInlineEnd: "0" }}>
          {() => (
            <Button
              type="default"
              htmlType="submit"
              disabled={!clientReady || !form.isFieldsTouched(true) || !!form.getFieldsError().filter(({ errors }) => errors.length).length}
              size="large"
              loading={loading}
              style={{ borderTopLeftRadius: "0", borderBottomLeftRadius: "0" }}
            >
              {"Entrar em contato"}
            </Button>
          )}
        </Form.Item>
      </Form>
      {response && <Typography.Paragraph style={{ fontSize: "16px", fontWeight: "400" }}>{response.userMessage}</Typography.Paragraph>}
      <Typography.Paragraph style={{ fontSize: "30px", fontWeight: "500" }}>{"Lançamento em breve."}</Typography.Paragraph>
    </S.CallToAction>
  );
};

export default CallToAction;
