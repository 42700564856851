import styled from "styled-components";

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: start;

  width: 100%;
  height: 100px;
  padding: 0 35px;

  position: fixed;
  top: 0;
`;
