import { Typography } from "antd";

import * as S from "./styles";

const Header = () => {
  return (
    <S.Header>
      <Typography.Paragraph style={{ fontSize: "24px", fontWeight: "500" }}>Charcot</Typography.Paragraph>
    </S.Header>
  );
};

export default Header;
