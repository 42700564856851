"use client";

import MainLayout from "@/components/LandingPage/MainLayout";

import * as Sections from "@/components/LandingPage";

export default function Home() {
  return (
    <MainLayout>
      <Sections.Header />
      <Sections.HeroSection />
      <Sections.CallToAction />
      <Sections.Footer />
    </MainLayout>
  );
}
