type tUserMessage = "Email cadastrado com sucesso." | "Ops, ocorreu um erro, tente novamente.";

export interface iUserResponse {
  code: number;
  sysMessage: JSON;
  userMessage: tUserMessage;
}

export const userMessages: Record<"success" | "error", iUserResponse["userMessage"]> = {
  success: "Email cadastrado com sucesso.",
  error: "Ops, ocorreu um erro, tente novamente.",
};
