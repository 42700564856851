import { Typography } from "antd";
import { useMemo } from "react";

import * as S from "./styles";

const Footer = () => {
  const year = useMemo(() => new Date().getFullYear(), []);

  return (
    <S.Footer>
      <Typography.Paragraph>{`VOA TECNOLOGIA LTDA - ${year}`}</Typography.Paragraph>
    </S.Footer>
  );
};

export default Footer;
