import styled from "styled-components";

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 50px;

  position: fixed;
  bottom: 0;
`;
