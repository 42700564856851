import { Typography } from "antd";

import * as S from "./styles";

const HeroSection = () => {
  return (
    <S.HeroSection>
      <Typography.Title style={{ fontSize: "42px", fontWeight: "600" }}>
        <span>Modelos de IA Generativa.</span>
        <span>Especializados em Saúde.</span>
        <span>Em português.</span>
      </Typography.Title>
    </S.HeroSection>
  );
};

export default HeroSection;
